.Toastify__toast-container {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    top: 86px;
}

.Toastify__toast {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 1.2;
    color: #fff;
    padding: 28px 27px;
    max-width: 440px;
    display: flex;
    align-items: center;
}

.Toastify__toast .Toastify__toast-body {
    padding: 0;
}

.Toastify__toast .Toastify__close-button {
    font-size: 0;
    margin-left: 10px;
    flex: 0 0 auto;
    opacity: 1;
    align-self: center;
}

.Toastify__toast .Toastify__close-button svg {
    fill: #fff;
    width: 18px;
    height: 18px;
}

.Toastify__toast--success {
    background-color: #17C981;
}

.Toastify__toast--error {
    background-color: #FF2D38;
}

.Toastify__toast--warning {
    background-color: #FCA600;
}

.Toastify__toast--info {
    background-color: #4671DB;
}

.alignRight {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
}

@media (max-width: 1250px) {
    .Toastify__toast-container {
        top: 106px;
    }
}

@media (max-width: 767px) {
    .Toastify__toast {
        max-width: 340px;
        font-size: 16px;
        padding: 20px;
    }

    .Toastify__toast-container {
        top: 74px;
    }
}

@media (max-width: 599px) {
    .Toastify__toast {
        width: auto;
        max-width: inherit;
    }

    .Toastify__toast-container {
        top: 0;
        right: 0;
    }
}